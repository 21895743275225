import React, { useEffect, useState } from 'react'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'
import { Button, Upload, Select, Form, InputNumber, Typography, Table, Space, message } from 'antd'
import { OrderedListOutlined, UploadOutlined } from '@ant-design/icons'
import {
  AttributeDictionaryValue,
  Domain,
  Layer,
  LayerAttributeDictionary,
  LayerAttributeDictionaryValue
} from '../../services/models'
import layersService from '../../services/layersService'
import { API_URL } from '../../services/apiService'
import tokenService from '../../services/tokenService'
import objImportsService, { ObjImport } from '../../services/objImportsService'

type Props = {}

const AdminImportPage: React.FC<Props> = () => {
  const [createNew, setCreateNew] = useState<boolean>(false)
  const [objImports, setObjImports] = useState<any[]>([])
  const [layers, setLayers] = useState<Layer[]>([])
  const [layerId, setLayerId] = useState<string>('0')

  const [edit, setEdit] = useState<{
        id: number,
        objImport: ObjImport,
        layer: Layer,
        headers: string[],
        values: { [key: number]: string[] }
    }>()

  const [formHeaders] = Form.useForm()
  const [formValues] = Form.useForm()

  const fetchData = () => {
    layersService.get()
      .then((r) => {
        setLayers(r.layers)
      })
    objImportsService.list()
      .then(r => {
        setObjImports(r.imports)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const get = (id: number) => {
    objImportsService.get(id)
      .then(data => {
        for (const [key, value] of Object.entries(data.objImport.mapHeaders)) {
          formHeaders.setFieldValue(key, value)
        }

        for (const [attributeId, values] of Object.entries(data.objImport.mapValues)) {
          for (const [value, valueId] of Object.entries(values)) {
            formValues.setFieldValue([attributeId, value], valueId)
          }
        }

        setEdit({
          objImport: data.objImport,
          id: data.id,
          layer: data.layer,
          headers: data.headers,
          values: data.values
        })
      }
      )
  }

  const renderSelectColumn = () => edit && <Select options={[
    { label: null, value: null },
    ...edit.headers.map((n, i) => ({
      label: n,
      value: i
    }))
  ]}/>

  const renderValue = (attribute: LayerAttributeDictionary) => {
    if (!edit) return null
    if (!edit.values[attribute.id]) {
      return null
    }

    const values = edit.values[attribute.id]

    return (
            <div>
                {values.map((v, i) => (
                    <Form.Item label={v} key={`${i}`} name={[attribute.id, v]}>
                        <Select options={attribute.values.map(v => ({ value: v.id, label: v.name }))}/>
                    </Form.Item>
                ))}
            </div>
    )
  }

  const getStatusName = (v: number) => {
    switch (v) {
      case 0:
        return 'UNKNOWN'
      case 1:
        return 'Сопоставление столбцов'
      case 2:
        return 'Сопоставление значений'
      case 3:
        return 'Готов к импорту'
      case 4:
        return 'Импорт завершен'
      default:
        return `STATUS: ${v}`
    }
  }

  return (
        <div>
            {edit && (
                <div>
                    <AdminPageTitle
                        title={'Сопоставление'}
                        buttonText={'Список'}
                        icon={<OrderedListOutlined/>}
                        onClickButton={() => setEdit(undefined)}
                    />
                    <Form
                        disabled={edit.objImport.status === 4}
                        labelCol={{ span: 6 }}
                        layout="horizontal"
                        form={formHeaders}
                        onFinish={(d) => objImportsService.updateMapHeaders(edit?.id, d).then(() => get(edit?.id))}>
                        <Typography.Title level={3}>Колоноки</Typography.Title>

                        <Form.Item label={'Название'} name={'name'}>
                            {renderSelectColumn()}
                        </Form.Item>

                        <Form.Item label={'description'} name={'description'}>
                            {renderSelectColumn()}
                        </Form.Item>

                        <Form.Item label={'lat'} name={'lat'}>
                            {renderSelectColumn()}
                        </Form.Item>

                        <Form.Item label={'long'} name={'long'}>
                            {renderSelectColumn()}
                        </Form.Item>
                        {edit.layer.attributeNumbers.map(a => (
                            <Form.Item key={a.id.toString()}
                                       label={a.name}
                                       name={`number_${a.id}`}>
                                {renderSelectColumn()}
                            </Form.Item>
                        ))}

                        {edit.layer.attributeDictionaries.map(a => (
                            <Form.Item key={a.id.toString()}
                                       label={a.name}
                                       name={`dictionary_${a.id}`}>
                                {renderSelectColumn()}
                            </Form.Item>
                        ))}

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit headers
                            </Button>
                        </Form.Item>

                    </Form>

                    <Form
                        disabled={edit.objImport.status === 4}
                        labelCol={{ span: 6 }}
                        layout="horizontal"
                        form={formValues}
                        onFinish={(d) => objImportsService.updateMapValues(edit?.id, d).then(() => get(edit?.id))}
                    >
                        <Typography.Title level={3}>Значений</Typography.Title>
                        {edit.layer.attributeDictionaries.map(a => (
                            <div key={a.id.toString()}>
                                <Typography.Title level={5}>{a.name}</Typography.Title>
                                {renderValue(a)}
                            </div>
                        ))}
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit values
                            </Button>
                        </Form.Item>
                    </Form>
                  <Button
                      disabled={edit.objImport.status !== 3}
                      type="primary"
                      onClick={() => objImportsService.start(edit?.id).then((data) => {
                        if (data.status === 'ok') {
                          message.success(`Создано: ${data.result.create}, обновлено: ${data.result.update}, ошибок: ${data.result.error}`)
                          fetchData()
                          setEdit(undefined)
                        } else {
                          message.error('Что-то пошло не так')
                        }
                      })}
                  >
                    Start import
                  </Button>
                </div>

            )}

            {!edit && !createNew && (
                <div>
                    <AdminPageTitle title={'Импорты'} buttonText={'Создать'} onClickButton={() => setCreateNew(true)}/>

                    <Table dataSource={objImports} columns={[
                      {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id'
                      },
                      {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name'
                      },
                      {
                        title: 'Created at',
                        dataIndex: 'createdAt',
                        key: 'createdAt'
                      },
                      {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (v) => getStatusName(v)
                      },
                      {
                        title: 'Действия',
                        key: 'actions',
                        render: (_: any, record: any) => (
                                <Space size="middle">
                                    <a onClick={() => get(record.id)}>Открыть</a>
                                </Space>
                        )
                      }
                    ]} rowKey={'id'}/>
                </div>
            )}
            {!edit && createNew && (
                <div>
                    <AdminPageTitle title={'Новый импорт'} buttonText={'Список'} icon={<OrderedListOutlined/>}
                                    onClickButton={() => setCreateNew(false)}/>

                    <Select
                        style={{ width: 200 }}
                        placeholder={'Выберите слой'}
                        options={layers.map(l => ({ label: l.name, value: l.id.toString() }))}
                        onChange={(e) => setLayerId(e)}
                    />

                    <Upload
                        action={`${API_URL}/obj-imports`}
                        headers={{ authorization: `Bearer ${tokenService.get()}` }}
                        name="file"
                        multiple={false}
                        data={{ layerId: +layerId }}
                        maxCount={1}
                        // fileList={fileList}
                        onChange={(e) => {
                          if (e.file.status === 'done') {
                            setCreateNew(false)
                            get(e.file.response.id)
                          }
                        }}
                    >
                        <Button icon={<UploadOutlined/>}>Загрузить</Button>
                    </Upload>
                </div>
            )}

        </div>
  )
}

export default AdminImportPage
