import { authAPI } from './apiService'
import { Layer } from './models'

const URL = '/obj-imports'

export type ObjImport = {
    id: number;
    userId: number;
    name: string;
    mime: string;
    size: number;
    path: string;
    layerId: number;
    createdAt: Date;
    updatedAt: Date;
    status: number;
    mapHeaders: { [key: string]: number };
    mapValues: { [key: number]: { [key: string]: number } };
}

export type ObjImportResponse = {
    id: number;
    objImport: ObjImport;
    layer: Layer;
    headers: string[];
    values: { [key: number]: string[] };
}

export type ObjImportStartResponse = {
    status: string;
    result: {create: number, error: number, update: number}
}

const objImportsService = {
  list: () => authAPI
    .get<any>(URL)
    .then((response) => response.data),

  get: (id: number) => authAPI
    .get<ObjImportResponse>(`${URL}/${id}`)
    .then((response) => response.data),

  start: (id: number) => authAPI
    .post<ObjImportStartResponse>(`${URL}/start`, { id })
    .then((response) => response.data),

  updateMapHeaders: (id: number, headers: {[key: string]: number}) => authAPI
    .post<any>(`${URL}/update-map-headers`, { id, headers })
    .then((response) => response.data),

  updateMapValues: (id: number, values: {[key: number]: {[key: string]: number}}) => authAPI
    .post<any>(`${URL}/update-map-values`, { id, values })
    .then((response) => response.data)

}

export default objImportsService
