import React, { useEffect, useState } from 'react'
import { Menu, Layout } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import type { MenuProps } from 'antd'
import layersService from '../../services/layersService'

type Props = {
    role: number
}
type MenuItem = Required<MenuProps>['items'][number];

const AdminLayout: React.FC<Props> = ({ role }) => {
  const [objs, setObjs] = useState<MenuItem[]>([])
  const fetchData = () => {
    layersService.get()
      .then((r) => {
        setObjs(r.layers.map((l) => ({
          key: `/objs/${l.id}`, label: l.name
        })))
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const navigate = useNavigate()
  const getRoleMenu = (role: number) : MenuItem[] => {
    switch (role) {
      case 2:
        return [
          { key: '/researches', label: 'Исследования' },
          { key: '/parser', label: 'Парсер' }
        ]
      case 9:
        return [
          { key: '/users', label: 'Пользователи' },
          { key: '/categories', label: 'Категории' },
          { key: '/services', label: 'Сервисы' },
          { key: '/researches', label: 'Исследования' },
          { key: '/parser', label: 'Парсер' },
          {
            label: 'Данные',
            type: 'group',
            children: [
              { key: '/domains', label: 'Домены' },
              { key: '/layers', label: 'Слои' },
              { key: '/attribute-numbers', label: 'Числа' },
              { key: '/attribute-dictionaries', label: 'Словари' },
              { key: '/import', label: 'Импорт' }
            ]
          },
          {
            label: 'Объекты',
            type: 'group',
            children: objs
          }
        ]
      default:
        return []
    }
  }

  return (
        <Layout.Content>
            <Layout>
                <Layout.Sider theme="light">
                    <Menu onClick={(e) => navigate(e.key)} items={getRoleMenu(role)}/>
                </Layout.Sider>
                <Layout>
                    <div style={{ padding: '10px' }}>
                        <Outlet/>
                    </div>
                </Layout>
            </Layout>
        </Layout.Content>
  )
}

export default AdminLayout
