import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Layout } from 'antd'
import useAuth from '../../hooks/useAuth'
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent'
import ProfilePage from '../../pages/ProfilePage/ProfilePage'
import AdminCategoriesPage from '../../pages/AdminCategoriesPage/AdminCategoriesPage'
import AdminServicesPage from '../../pages/AdminServicesPage/AdminServicesPage'
import AdminLayout from '../../layouts/AdminLayout/AdminLayout'
import AdminIndexPage from '../../pages/AdminIndexPage/AdminIndexPage'
import AdminResearchesPage from '../../pages/AdminResearchesPage/AdminResearchesPage'
import { AdminResearchesCreatePage } from '../../pages/AdminResearchesPage/pages/AdminResearchesCreatePage'
import { AdminResearchesEditPage } from '../../pages/AdminResearchesPage/pages/AdminResearchesEditPage'
import { Profile, RootCategory } from '../../services/models'
import AdminUsersPage from '../../pages/AdminUsersPage/AdminUsersPage'
import CatalogPage from '../../pages/CatalogPage/CatalogPage'
import { AdminResearchesViewPage } from '../../pages/AdminResearchesPage/pages/AdminResearchesViewPage'
import SearchPage from '../../pages/SearchPage/SearchPage'
import AdminParserPage from '../../pages/AdminParserPage/AdminParserPage'
import FeedPage from '../../pages/FeedPage/FeedPage'
import ClientLayout from '../../layouts/ClientLayout/ClientLayout'
import userService from '../../services/userService'
import CatalogLayout from '../../layouts/CatalogLayout/CatalogLayout'
import ResearchPage from '../../pages/ResearchPage/ResearchPage'
import ServicePage from '../../pages/ServicePage/ServicePage'
import CatalogIndexPage from '../../pages/CatalogIndexPage/CatalogIndexPage'
import { useAppDispatch } from '../../app/hooks'
import profileService from '../../services/profileService'
import { setPermItems } from '../../features/perm/permSlice'
import AdminDomainsPage from '../../pages/AdminDomainsPage/AdminDomainsPage'
import AdminLayersPage from '../../pages/AdminLayersPage/AdminLayersPage'
import AdminObjsPage from '../../pages/AdminObjsPage/AdminObjsPage'
import AdminAttributeNumbersPage from '../../pages/AdminAttributeNumbersPage/AdminAttributeNumbersPage'
import AdminAttributeDictionaryPage from '../../pages/AdminAttributeDictionaryPage/AdminAttributeDictionaryPage'
import AdminAttributeDictionaryValuesPage
  from '../../pages/AdminAttributeDictionaryValuesPage/AdminAttributeDictionaryValuesPage'
import AdminImportPage from '../../pages/AdminImportPage/AdminImportPage'

type Props = {
    profile: Profile
}

const AuthorizedRouter: React.FC<Props> = ({ profile }) => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { logout } = useAuth()
  const home = () => navigate('/')
  const search = (q: string) => navigate({ pathname: '/search', search: '?q=' + q })

  const [categories, setCategories] = useState<RootCategory[]>([])
  useEffect(() => {
    userService.getCategories().then(data => setCategories(data.tree))
    profileService.permissions().then((data) => dispatch(setPermItems(data.data)))
  }, [])

  const routesForRole = (role: number) => {
    switch (role) {
      case 0:
        return (
                    <Routes>
                        <Route path="*" element={<div>ROLE_0</div>}/>
                    </Routes>
        )
      case 1:
        return (
                    <Routes>
                        <Route path="/" element={<ClientLayout categories={categories}/>}>
                            <Route index element={<CatalogIndexPage categories={categories}/>}/>
                            <Route path="catalog" element={<CatalogLayout categories={categories}/>}>
                                <Route path=":rootCategoryId" element={<CatalogPage categories={categories}/>}/>
                                <Route path=":rootCategoryId/:subCategoryId"
                                       element={<CatalogPage categories={categories}/>}/>
                                <Route path=":rootCategoryId/:subCategoryId/:serviceId"
                                       element={<ServicePage categories={categories}/>}/>
                                <Route path=":rootCategoryId/:subCategoryId/:serviceId/:researchId"
                                       element={<ResearchPage/>}/>
                            </Route>
                            <Route path="feed" element={<FeedPage/>}/>
                            <Route path="search" element={<SearchPage/>}/>
                            <Route path="profile" element={<ProfilePage profile={profile}/>}/>
                        </Route>
                    </Routes>
        )
      case 2:
        return (
                    <Routes>
                        <Route path="/" element={<AdminLayout role={role}/>}>
                            <Route index element={<AdminIndexPage/>}/>
                            <Route path="parser" element={<AdminParserPage/>}/>
                            <Route path="researches" element={<AdminResearchesPage/>}/>
                            <Route path="researches/create" element={<AdminResearchesCreatePage/>}/>
                            <Route path="researches/edit/:researchId" element={<AdminResearchesEditPage/>}/>
                            <Route path="researches/view/:researchId" element={<AdminResearchesViewPage/>}/>
                        </Route>
                        <Route path="/profile" element={<ProfilePage profile={profile}/>}/>
                    </Routes>
        )
      case 9:
        return (
                    <Routes>
                        <Route path="/" element={<AdminLayout role={role}/>}>
                            <Route index element={<AdminIndexPage/>}/>
                            <Route path="parser" element={<AdminParserPage/>}/>
                            <Route path="users" element={<AdminUsersPage/>}/>
                            <Route path="categories" element={<AdminCategoriesPage/>}/>
                            <Route path="services" element={<AdminServicesPage/>}/>
                            <Route path="researches" element={<AdminResearchesPage/>}/>
                            <Route path="researches/create" element={<AdminResearchesCreatePage/>}/>
                            <Route path="researches/edit/:researchId" element={<AdminResearchesEditPage/>}/>
                            <Route path="researches/view/:researchId" element={<AdminResearchesViewPage/>}/>
                            <Route path="domains" element={<AdminDomainsPage/>}/>
                            <Route path="layers" element={<AdminLayersPage/>}/>
                            <Route path="objs/:layerId" element={<AdminObjsPage/>}/>
                            <Route path="attribute-numbers" element={<AdminAttributeNumbersPage/>}/>
                            <Route path="attribute-dictionaries" element={<AdminAttributeDictionaryPage/>}/>
                            <Route path="attribute-dictionary-values/:attributeDictionaryId" element={<AdminAttributeDictionaryValuesPage/>}/>
                            <Route path="import" element={<AdminImportPage/>}/>
                        </Route>
                        <Route path="/profile" element={<ProfilePage profile={profile}/>}/>
                    </Routes>
        )
      default:
        return (
                    <Routes>
                        <Route path="*" element={<h1>error: user.role {profile.role}</h1>}/>
                    </Routes>
        )
    }
  }

  return (
        <Layout>
            <HeaderComponent
                onHome={home}
                onLogout={logout}
                profile={profile}
                onSearch={search}
            />
            {routesForRole(profile.role)}
            <Layout.Footer style={{ textAlign: 'center' }}>Prisma platform ©2024</Layout.Footer>
        </Layout>
  )
}

export default AuthorizedRouter
